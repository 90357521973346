import {
  COLORS,
  IconButton,
  LoadingSkeleton,
  SayIcon,
  Text,
} from "@asayinc/component-library";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useState } from "react";

import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { isProxyCommunication } from "src/pages/CommunicationDetails/utils";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { ProfileDialog } from "../ProfileDialog";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { getUniqueAccountNumbersFromCommunications } from "src/utils/utils";
export function NavigationBar() {
  const theme = useTheme();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isBrokerLoading, data: broker } = useBrokerQuery(brokerId);
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnMenuClick = () => {
    // We want to capture the event when opening the dialog
    if (!isDialogOpen) {
      track({
        name: TrackEventType.Profile,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
    }
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isLoading = isCommunicationLoading || isBrokerLoading;
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const accountNumbers = getUniqueAccountNumbersFromCommunications(
    communication ? [communication] : []
  );
  const [firstAccountNumber] = accountNumbers;

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        position: "fixed",
        top: "0px",
        zIndex: 1000,
        borderBottom: isSmall
          ? `1px solid ${COLORS.P_OUTLINE}`
          : `6px solid ${isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main}`,
      }}
    >
      <Box
        component="nav"
        sx={{
          backgroundColor: "#FFFFFF",
          height: "48px",
          px: "17px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <Box sx={{ width: "120px" }}>
            {isLoading ? (
              <LoadingSkeleton />
            ) : (
              <img
                src={broker?.theme.logo}
                style={{ height: broker?.theme.logoHeight }}
                alt="Broker Logo"
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderLeft:
                  communication && !isProxyCommunication(communication)
                    ? "none"
                    : "1px solid #E0E0E0",
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              }}
            >
              {isLoading ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <LoadingSkeleton sx={{ width: "120px", ml: 4 }} />
                  <LoadingSkeleton sx={{ width: "189px", ml: 4, mt: 0.5 }} />
                </Box>
              ) : (
                <>
                  <Text
                    data-testid="bar-customer-name"
                    variant="subtitle3"
                    sx={{ ml: 4 }}
                  >
                    {communication?.customer?.name || undefined}
                  </Text>
                  <Text
                    data-testid="account-number"
                    variant="body2"
                    emphasis="medium"
                    sx={{ ml: 4, fontSize: "12px" }}
                  >
                    Account #: {firstAccountNumber}
                  </Text>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "none",
                [theme.breakpoints.down("sm")]: {
                  display: "block",
                },
              }}
            >
              {isLoading ? (
                <LoadingSkeleton sx={{ width: "24px" }} />
              ) : (
                <IconButton
                  onClick={handleOnMenuClick}
                  data-testid="profile-icon-button"
                >
                  {isDialogOpen ? <Close /> : <SayIcon name="ProfileOutline" />}
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {communication && (
        <ProfileDialog
          communication={communication}
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
        />
      )}
    </Box>
  );
}
