import { CustomerPreference } from "./types";
import { warrenGApi } from "../rootApi";

export const customerApi = warrenGApi.injectEndpoints({
  endpoints: (builder) => ({
    customerPreferences: builder.query<
      CustomerPreference[],
      { brokerId: string; customerId: string }
    >({
      query: ({ brokerId, customerId }) => ({
        url: `${brokerId}/customers/${customerId}/preferences/`,
      }),
      providesTags: ["CustomerPreferences"],
    }),
    updateCustomerPreferences: builder.mutation<
      { subscriptionPreferences: CustomerPreference[] },
      {
        brokerId: string;
        customerId: string;
        preferences: Omit<CustomerPreference, "description" | "label">[];
      }
    >({
      query: ({ brokerId, customerId, preferences }) => ({
        url: `${brokerId}/customers/${customerId}/preferences/`,
        method: "POST",
        body: preferences,
      }),
      invalidatesTags: ["CustomerPreferences"],
    }),
  }),
});

export const {
  useCustomerPreferencesQuery,
  useUpdateCustomerPreferencesMutation,
} = customerApi;
