import { ButtonV2, LoadingSkeleton, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  useCustomerPreferencesQuery,
  useUpdateCustomerPreferencesMutation,
} from "src/store/warrenG/customer";
import {
  CustomerPreferenceType,
  CustomerPreference,
} from "src/store/warrenG/customer/types";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { PreferenceRow } from "../PreferenceRow";

export function CustomerSubscriptionForm() {
  const { brokerId, customerId } = useParams() as {
    brokerId: string;
    customerId: string;
  };
  const { data, isLoading } = useCustomerPreferencesQuery({
    brokerId,
    customerId,
  });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const [updatePreferences] = useUpdateCustomerPreferencesMutation();

  const form = useForm<{
    preferences: CustomerPreference[];
  }>({
    defaultValues: {
      preferences: [],
    },
  });
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const { fields, update } = useFieldArray({
    control,
    name: "preferences",
  });

  useEffect(() => {
    if (data) {
      const preferences = data.map((preference) => ({
        ...preference,
      }));
      // Put "unsubscribe from all" at the bottom of the list
      const allNotifications = preferences.find(
        (preference) =>
          preference.notificationType ===
          CustomerPreferenceType.ALL_NOTIFICATIONS
      );
      if (allNotifications) {
        const index = preferences.indexOf(allNotifications);
        preferences.splice(index, 1);
        preferences.push(allNotifications);
      }
      reset({
        preferences,
      });
    }
  }, [data, reset]);

  const onSubmitForm = async (data: { preferences: CustomerPreference[] }) => {
    const payload = data.preferences.map((preference) => ({
      notificationType: preference.notificationType,
      unsubscribed: preference.unsubscribed,
    }));
    await updatePreferences({
      brokerId,
      customerId,
      preferences: payload,
    }).unwrap();
  };

  // Unselect all preferences if the "All Notifications" preference is selected
  const handleUpdate = (
    notificationType: CustomerPreferenceType,
    value: boolean
  ) => {
    const values = getValues();
    // Want to check if "Unsubscribe from all notifications" is selected
    const allNotifications = values.preferences.find(
      (preference) =>
        preference.notificationType === CustomerPreferenceType.ALL_NOTIFICATIONS
    );

    // If "Unsubscribe from all notifications" is selected, unselect all other preferences
    if (
      notificationType === CustomerPreferenceType.ALL_NOTIFICATIONS &&
      value
    ) {
      values.preferences.forEach((preference, index) => {
        if (
          preference.notificationType !==
          CustomerPreferenceType.ALL_NOTIFICATIONS
        ) {
          update(index, {
            ...preference,
            unsubscribed: false,
          });
        }
      });
    }
    // If any other preference is selected, unselect "Unsubscribe from all notifications"
    else if (
      notificationType !== CustomerPreferenceType.ALL_NOTIFICATIONS &&
      value &&
      allNotifications?.unsubscribed
    ) {
      values.preferences.forEach((preference, index) => {
        if (
          preference.notificationType ===
          CustomerPreferenceType.ALL_NOTIFICATIONS
        ) {
          update(index, {
            ...preference,
            unsubscribed: false,
          });
        }
      });
    }
  };

  return (
    <>
      <Stack
        sx={{
          maxWidth: "752px",
          height: "100%",
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {isLoading || isBrokerLoading ? (
          <Stack
            sx={{
              mb: 8,
              p: 6,
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            <LoadingSkeleton sx={{ mb: 4, py: 2, width: "40%" }} />
            <LoadingSkeleton sx={{ mb: 4 }} />
            <LoadingSkeleton sx={{ mb: 4 }} />
            <LoadingSkeleton sx={{ mb: 4 }} />
            <LoadingSkeleton sx={{ mb: 4 }} />
          </Stack>
        ) : (
          <FormProvider {...form}>
            <Text variant="body1" sx={{ mb: 5 }}>
              Say sends notifications to enrich the investor experience. You may
              select to unsubscribe from the following communications.
            </Text>
            <form data-testid="customer-subscription-form">
              {fields.map((preference, index) => (
                <PreferenceRow
                  key={preference.id}
                  name={`preferences.${index}.unsubscribed`}
                  onUpdate={handleUpdate}
                  preference={preference}
                />
              ))}
            </form>
          </FormProvider>
        )}
      </Stack>
      <ButtonV2
        sx={{ width: "100%", mt: 5 }}
        onClick={handleSubmit(onSubmitForm)}
        disabled={!isDirty}
      >
        Save changes
      </ButtonV2>
    </>
  );
}
