import {
  Communication,
  CorporateActionCommunication,
  ProxyCommunication,
} from "src/types";
import { formatDate } from "src/utils/formatDate";
import { isPassedCutoffDate } from "src/utils/utils";

export function getPrimaryCtaText(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    return isPassedCutoffDate(communication) ? "View election" : "Elect now";
  }

  return isPassedCutoffDate(communication) ? "View ballot" : "Vote now";
}

export function getCutoffDate(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const { corporateActionEvent } =
      communication as CorporateActionCommunication;
    const { electionCutoff } = corporateActionEvent;
    return formatDate({ date: electionCutoff });
  }

  const { proxyEvent } = communication as ProxyCommunication;
  const { voteCutoff } = proxyEvent;
  return formatDate({ date: voteCutoff });
}

export function isCommunicationViewed(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const { electionStatus } = communication as CorporateActionCommunication;
    return electionStatus !== "not_viewed";
  }

  const { voteStatus } = communication as ProxyCommunication;
  return voteStatus !== "not_viewed";
}
