import { IconButton, SayIcon, Text } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { LoadingConfirmationDialog } from "src/components/molecules/LoadingConfirmationDialog";
import { useBrokerQuery } from "src/store/warrenG/broker";

interface IWithdrawSuccessDialogProps {
  isOpen: boolean;
  issuerName: string;
  onClose: () => void;
}

export function WithdrawSuccessDialog({
  isOpen,
  issuerName,
  onClose,
}: IWithdrawSuccessDialogProps) {
  const theme = useTheme();
  const { brokerId } = useParams() as { brokerId: string };
  const { isLoading } = useBrokerQuery(brokerId);

  return (
    <Dialog open={isOpen} onClose={onClose} disableScrollLock>
      {isLoading ? (
        <LoadingConfirmationDialog />
      ) : (
        <>
          <DialogTitle>
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ p: 8, mt: 3, maxWidth: "320px" }}
            data-testid="withdraw-success-dialog"
          >
            <Stack justifyContent="center" alignItems="center">
              <Box>
                <SayIcon
                  name="WithdrawVoteOutline"
                  size="48px"
                  color={theme.palette.l4.main}
                />
              </Box>
              <Text
                variant="h5"
                sx={{
                  mt: 8,
                  color: theme.palette.l4.main,
                  textAlign: "center",
                }}
              >
                Your vote has been withdrawn
              </Text>
              <Text sx={{ mt: 6, textAlign: "center" }} variant="body2">
                Your vote will no longer be counted at {issuerName}
              </Text>
              <Text
                variant="body3"
                emphasis="medium"
                sx={{ mt: 8, textAlign: "center" }}
              >
                Changed your mind? You can edit your ballot until the vote
                cutoff.
              </Text>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
