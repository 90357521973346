import { Text } from "@asayinc/component-library";
import { Box, capitalize, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { ProxyCommunication } from "src/types";
import { Proposal } from "src/types/Communication";
import { ProposalCard } from "../ProposalCard";
import { isPassedCutoffDate } from "src/utils/utils";
import { SelectAllProposals } from "../SelectAllProposals";
import { isContestedMeeting } from "src/pages/Communication/components/EventHeader/utils";
import { toWords } from "number-to-words";

interface IGroupedProposalProps {
  communication: ProxyCommunication;
  groupedProposal: Proposal[];
}

export function GroupedProposal({
  communication,
  groupedProposal,
}: IGroupedProposalProps) {
  const theme = useTheme();
  const { setValue, watch } = useFormContext();

  const exampleProposal = groupedProposal[0];
  const { groupMaximumVotes, groupNumber, title, voteChoices } =
    exampleProposal;

  const handleOnAllSelect = (proposalId: string, value: string) => {
    setValue(proposalId as never, value as never, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const isContested = isContestedMeeting(communication);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        p: 6,
        mb: 8,
        "&:last-child": { mb: 5 },
        [theme.breakpoints.down("sm")]: {
          mb: 4,
          "&:last-child": { mb: 0 },
        },
      }}
      className="proposal"
      id={`group-${groupNumber}`}
    >
      <Text emphasis="high" variant="subtitle1">
        {`${groupNumber}. ${title}`}
      </Text>
      {!!groupMaximumVotes && (
        <Text variant="subtitle2" sx={{ mt: 3 }}>
          There are {toWords(groupMaximumVotes)} open seats on the board. You
          can only vote &apos;For&apos; for {toWords(groupMaximumVotes)}{" "}
          directors max.
        </Text>
      )}
      {!isContested && groupedProposal.length > 1 && (
        <SelectAllProposals
          communication={communication}
          formValues={watch()}
          groupedProposal={groupedProposal}
          isAttendingMeeting={!!communication.meetingAttendance?.legalProxy}
          isPassedCutoffDate={isPassedCutoffDate(communication)}
          onChange={handleOnAllSelect}
          options={voteChoices.map((choice) => ({
            label: capitalize(choice).replaceAll("_", " ") + " all",
            value: choice,
          }))}
        />
      )}
      <Box>
        {groupedProposal.map((proposal, index) => (
          <ProposalCard
            communication={communication}
            groupedProposals={groupedProposal}
            isAttendingMeeting={!!communication.meetingAttendance?.legalProxy}
            isPassedCutoffDate={isPassedCutoffDate(communication)}
            key={`grouped-proposal-${index}`}
            letterIndex={index}
            proposal={proposal}
          />
        ))}
      </Box>
    </Box>
  );
}
