import { CORPORATE_ACTION_EVENT_TYPE_LABEL } from "src/utils/utils";
import { CorporateActionCommunication } from "src/types";

export const getParticipateQuestion = (
  communication: CorporateActionCommunication
) => {
  const { corporateActionEvent, positions, security } = communication;
  const { issuerName } = security;
  const { type } = corporateActionEvent;
  const sharesOwned = positions.reduce((acc, position) => {
    return Number(position.sharesOwned) + acc;
  }, 0);

  switch (type) {
    case "buyback":
    case "buyback_auction":
      return `Please indicate whether or not you would like to sell your shares back to ${issuerName}. You have ${sharesOwned} ${
        sharesOwned > 1 ? "shares" : "share"
      } in your account.`;
    case "offer_to_purchase":
    case "offer_to_purchase_auction":
    case "odd_lot_offer":
      return `Please indicate whether or not you would like to sell your ${
        sharesOwned > 1 ? "shares" : "share"
      }. You have ${sharesOwned} ${
        sharesOwned > 1 ? "shares" : "share"
      } in your account.`;
    case "dividend_option":
      return `How would you like to receive your dividend?`;
    case "merger_consideration_election":
      return `Please indicate how you would like to receive your exchange. You have ${sharesOwned} ${
        sharesOwned > 1 ? "shares" : "share"
      } in your account.`;
    case "exchange_offer":
      return `Please indicate below whether you would like to exchange your shares. You have ${sharesOwned} ${
        sharesOwned > 1 ? "shares" : "share"
      } in your account.`;
    case "consent_solicitation":
      return `Do you grant your consent?`;
    case "dividend_reinvestment_plan":
      return `Do you want to participate in ${issuerName}'s DRIP?`;
    case "rights_offer":
    case "rights_offer_oversubscription":
      return `Please indicate whether or not you would like to exchange your rights. You have ${sharesOwned} ${
        sharesOwned > 1 ? "rights" : "right"
      } in your account.`;
    case "informational":
      return `Do you want to participate in this corporate action?`;
    default:
      return `Do you want to participate in this ${CORPORATE_ACTION_EVENT_TYPE_LABEL[
        type
      ].toLowerCase()}?`;
  }
};

export function getDefaultValues(communication: CorporateActionCommunication) {
  const { electionResults, positions } = communication;

  if (electionResults.length === 0) {
    return {
      choice: "",
      choiceDetails: positions.map((position) => {
        return {
          positionId: position.id,
          sharesElected: "",
          shareholderBid: "",
          rightsElected: "",
          oversubscriptionElected: "",
        };
      }),
    };
  }

  return {
    choice: electionResults[0].choice,
    choiceDetails: positions.map((position) => {
      const electionResult = electionResults.find(
        (result) =>
          result.accountIdentifier === position.accountIdentifier &&
          result.accountType === position.accountType
      );

      const options = electionResult
        ? Object.keys(electionResult.options).reduce((acc, key) => {
            return {
              [key]: electionResult.options[key] || "",
              ...acc,
            };
          }, {})
        : {};

      return {
        positionId: position.id,
        sharesElected: "",
        shareholderBid: "",
        rightsElected: "",
        oversubscriptionElected: "",
        ...options,
      };
    }),
  };
}
