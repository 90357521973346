import { COLORS, LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box, Stack, useTheme, DialogContent } from "@mui/material";
import { useParams } from "react-router-dom";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { useCustomerPreferencesQuery } from "src/store/warrenG/customer";
import { CustomerSubscriptionForm } from "./components/CustomerSubscriptionForm";

export function Subscriptions() {
  const { brokerId, customerId } = useParams() as {
    brokerId: string;
    customerId: string;
  };
  const { isLoading: isBrokerLoading, data: broker } = useBrokerQuery(brokerId);
  const { isLoading: isCustomerPreferencesLoading } =
    useCustomerPreferencesQuery({
      brokerId,
      customerId,
    });
  const theme = useTheme();

  const isLoading = isBrokerLoading || isCustomerPreferencesLoading;

  return (
    <Stack alignItems="center" height="100%" flexGrow={1}>
      <Stack
        sx={{
          maxWidth: "680px",
          width: "100%",
          px: 5,
          pb: 5,
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            borderTop: `6px solid ${
              isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
            }`,
            marginTop: 8,
            borderRadius: "0px 0px 8px 8px",
            backgroundColor: "#FFFFFF",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
              marginTop: 4,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 6,
              width: "120px",
            }}
          >
            {isLoading ? (
              <LoadingSkeleton sx={{ width: "120px" }} />
            ) : (
              <img
                src={broker?.theme.logo}
                style={{ height: broker?.theme.logoHeight }}
                alt="Broker Logo"
              />
            )}
          </Box>
          <Stack
            sx={{
              mb: 6,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{ overflow: "hidden" }}
              data-testid="customer-subscription-dialog-content"
            >
              <Box
                sx={{
                  py: 5,
                  borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
                }}
              >
                <Text
                  variant="h5"
                  sx={{ flexGrow: 1, textAlign: "center", mt: "-20px" }}
                >
                  Email Subscription Settings
                </Text>
              </Box>
              <DialogContent
                sx={{ p: 6, display: "flex", flexDirection: "column" }}
              >
                <CustomerSubscriptionForm />
              </DialogContent>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
